import React, {useEffect, useMemo, useRef, useState} from "react";
import {AtSymbolIcon,
  PencilIcon,
} from "@heroicons/react/outline";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGithub,
  faLinkedin,
  faMediumM as faMedium,
} from "@fortawesome/free-brands-svg-icons";
import leftBackground from "../assets/left_background.svg";
import rightBackground from "../assets/right_background.svg";
import { useQuery } from "@tanstack/react-query";
import NotionLogo from "../components/NotionLogo";
import {ChevronDoubleLeftIcon, ChevronDoubleRightIcon} from "@heroicons/react/solid";
import HashnodeLogo from "../components/HashnodeLogo";

function Home() {
  const bookQuery = useQuery(["books"], () =>
    fetch(
      "https://us-central1-levi-barker-product.cloudfunctions.net/getBookshelf"
    ).then((res) => res.json())
  );
  const highlightsQuery = useQuery(["highlights"], () =>
      fetch(
          "https://us-central1-levi-barker-product.cloudfunctions.net/getHighlights"
      ).then((res) => res.json())
  );
  const highlight = useMemo(() => highlightsQuery.data?.highlights[Math.round(Math.random() * 2)], [highlightsQuery.data])
  const [navOpen, setNavOpen] = useState(false);

  const bookshelfRef: any = useRef();

  useEffect(() => {
    console.log(highlightsQuery.data);
  }, [highlightsQuery.data]);

  const goTo = (url: string) => {
    window.open(url, "_blank");
  };

  const scrollBookshelfLeft = () => {
    bookshelfRef.current.scrollTo({
      left: bookshelfRef.current.scrollLeft - 500,
      behavior: 'smooth'
    })
  }

  const scrollBookshelfRight = () => {
    bookshelfRef.current.scrollTo({
      left: bookshelfRef.current.scrollLeft + 500,
      behavior: 'smooth'
    })
  }

  return (
    <div className="w-screen h-screen bg-stone-100 text-stone-600 flex flex-col overflow-auto">
      <nav
        id="sidenav"
        className={`absolute z-40 pt-20 bg-stone-800 ${
          navOpen ? "w-40 p-2" : "w-0 p-0"
        } h-screen flex flex-col items-start transition-all duration-300 ease-in-out overflow-hidden shadow`}
      >
        <div>Logo</div>
      </nav>
      <div
        id="mobile-header"
        className="shadow-lg z-50 fixed w-full h-16 px-4 bg-stone-100/90 flex items-center justify-between"
      >
        <div className="flex items-center gap-2">
          {/* <button className="hover:bg-stone-300/25 p-3 rounded-full" onClick={() => setNavOpen(!navOpen)}>
        <MenuIcon className="h-6 w-6" />
      </button> */}
          <span className="font-semibold text-md sm:text-2xl text-[#187dff]">
            levi<span className="text-stone-600">barker</span>.com
          </span>
        </div>
        <div className="flex items-center gap-1">
          <button
              onClick={() => goTo("https://lbarker.notion.site")}
              className="flex items-center justify-center px-2 py-2 gap-2 rounded-full cursor-pointer text-stone-700 hover:bg-stone-300/50"
          >
            <NotionLogo className="w-6 h-6 sm:w-5 sm:h-5"/>
            <span className="hidden sm:inline font-semibold text-sm">
              Notion
            </span>
          </button>
          <button
              onClick={() => goTo("https://blog.levibarker.com")}
              className="flex items-center justify-center px-2 py-2 gap-2 rounded-full cursor-pointer text-stone-700 hover:bg-stone-300/50"
          >
            <HashnodeLogo className="w-6 h-6 sm:w-5 sm:h-5"/>
            <span className="hidden sm:inline font-semibold text-sm">
              Hashnode
            </span>
          </button>
          <button
              onClick={() => goTo("https://github.com/LeviBarker")}
              className="flex items-center justify-center px-2 py-2 gap-2 rounded-full cursor-pointer text-stone-700 hover:bg-stone-300/50"
          >
            <FontAwesomeIcon
                icon={faGithub}
                className="w-6 h-6 sm:w-5 sm:h-5"
            />
            <span className="hidden sm:inline font-semibold text-sm">
              GitHub
            </span>
          </button>
          <button
              onClick={() => goTo("https://www.linkedin.com/in/barkerlevi/")}
              className="flex items-center justify-center px-2 py-2 gap-2 rounded-full cursor-pointer text-[#0b66c2] hover:bg-stone-300/50"
          >
            <FontAwesomeIcon
                icon={faLinkedin}
                className="w-6 h-6 sm:w-5 sm:h-5"
            />
            <span className="hidden sm:inline font-semibold text-sm">
              LinkedIn
            </span>
          </button>
          {/* <button className="w-8 h-8 flex items-center justify-center p-2 rounded-full hover:bg-stone-300">
      <FontAwesomeIcon className="text-[#1DA1F2]" size="lg" icon={faTwitter} />
      </button> */}
        </div>
        {/* <nav id="header-nav" className="sm:inline xs:hidden">Header Nav</nav> */}
      </div>
      <section
          id="content"
          className="w-full pt-16"
          onClick={() => setNavOpen(false)}
      >
        <div
            className="relative flex items-center justify-center sm:flex-row xs:flex-col"
            style={{height: "calc(100vh - 64px)"}}
        >
          <img
              alt={"background left"}
              className="z-10 absolute left-0 bottom-0 h-full"
            src={leftBackground}
          />
          <img
              alt={"background right"}
            className="z-10 absolute right-0 bottom-0 h-full hidden sm:inline"
            src={rightBackground}
          />
          <article className="md:w-96 xs:w-full md:p-2 p-16 z-20">
            <span className="fade-in-1 text-[36px] font-bold ">
              <span className="wave">👋</span> Hello, I'm{" "}
              <span className="text-[#187dff]">Levi</span>
            </span>
            <p className="fade-in-2 pt-4 pb-4 text-lg">
              I've been creating websites and applications for 8+ years, and am
              a Google-certified UX designer.
            </p>
            <span className="fade-in-3 text-sm text-stone-500 italic">
              Check out some of my latest side projects at&nbsp;
              <a
                  className="text-[#187dff] hover:underline"
                  target="_blank"
                  href="https://labs.levibarker.com"
              >
                https://labs.levibarker.com
              </a>
            </span>
            <div className="flex gap-6 fade-in-4 items-center pt-4">
              <a
                  href="mailto:levi@levibarker.com"
                  target="_blank"
                  className="bg-[#187dff] text-stone-200 rounded-full px-4 py-2 flex items-center gap-2 h-18"
              >
                <AtSymbolIcon className="h-4 w-4" />
                <span>Connect</span>
              </a>
              <div className="pt-24 w-20 bg-no-repeat bg-left bg-contain bg-[url('assets/google_ux_badge.png')]"></div>
            </div>
          </article>
          <article className="fade-in-4 z-20 hidden md:inline w-96 h-96 bg-[url('assets/blob_subject.png')] bg-center bg-no-repeat bg-contain"></article>
        </div>
        <div className="p-8 flex items-center justify-center">
          {highlightsQuery.isLoading ?
              (<div className="w-full flex justify-center">Loading...</div>) :
              (<div className="bg-slate-200/10 rounded-xl p-4 max-w-2xl">
                <blockquote className="relative flex gap-4 mt-4">
                  <NotionLogo className="w-16 h-16 hidden sm:inline shrink-0" />
                  <div>
                    <p className="border-l-4 pl-4">
                      "Learning in the open" is a philosophy that emphasizes the importance of transparency and sharing
                      in the learning process. By documenting and sharing your learning journey, you not only reinforce
                      your own understanding but also provide valuable insights and resources to others. This approach
                      fosters a collaborative environment where knowledge is freely exchanged, and everyone can benefit
                      from collective experiences and discoveries.
                    </p>
                    <br/>
                    <p>
                      See my resources related to "learning in the open" <a
                        className="text-[#187dff] hover:underline"
                        target="_blank"
                        href="https://lbarker.notion.site/Learning-in-the-Open-d8f3589593e6476992b947c9ac3d30f5?pvs=4"
                    >
                      here
                    </a>.
                    </p>
                  </div>
                </blockquote>
              </div>)}
        </div>
        <div className="relative bg-slate-800 text-stone-100 overflow-auto">
          <div className="w-full flex justify-center text-2xl font-bold py-8 ">
            Bookshelf
            <button className="absolute px-8 left-0 top-0 h-full z-50 bg-slate-600/10" onClick={scrollBookshelfLeft}>
              <ChevronDoubleLeftIcon className="h-6"/>
            </button>
            <button className="absolute px-8 right-0 top-0 h-full z-50 bg-slate-600/10" onClick={scrollBookshelfRight}>
              <ChevronDoubleRightIcon className="h-6"/>
            </button>
          </div>
          <div ref={bookshelfRef} className="relative w-full flex gap-6 snap-x overflow-x-auto pb-14">

            <div className="snap-center shrink-0">
              <div className="shrink-0 w-4 sm:w-48"></div>
            </div>
            {bookQuery.isLoading ? (
                <div className="w-full flex justify-center">Loading...</div>
            ) : (
                bookQuery.data?.map((book: any) => (
                    <div
                        onClick={() => goTo(book.link)}
                        className="snap-center shrink-0 px-0 sm:px-8 first:pr-16 last:pl-16 flex gap-1 sm:gap-4"
                    >
                      <div
                          className="relative shrink-0 h-40 w-32 rounded-xl shadow-xl bg-stone-200 bg-center bg-cover flex items-start justify-end p-2"
                          style={{backgroundImage: `url('${book.img}')`}}
                      >
                        {/* {product.metadata.previewLink && <a href={product.metadata.previewLink} target="_blank" className="bg-stone-800/50 text-stone-200 rounded-full px-3 py-1 flex items-center gap-2">
                  <EyeIcon className="h-4 w-4" />
                  <span className="text-sm">Preview</span>
                </a>} */}
                  </div>
                  <div className="items-center justify-between gap-2 hidden sm:flex">
                    <div className="flex flex-col items-start">
                      <div className="pt-2 font-semibold text-lg">
                        {book.title}
                      </div>
                      <div>{book.author}</div>
                      {book.link && (
                        <div
                          onClick={() => goTo(book.link)}
                          className="hidden mt-4 cursor-pointer text-stone-700 text-sm bg-stone-50 hover:bg-stone-200 h-8 rounded-2xl shadow sm:flex items-center gap-1 justify-center px-4"
                        >
                          <PencilIcon className="h-4 w-4" />
                          {book.highlights}&nbsp;Highlights
                        </div>
                      )}
                    </div>
                    {/* <a href={book.amazonLink} target="_blank" className="bg-[#187dff] text-stone-200 rounded-full px-4 py-2 flex items-center gap-2">
                  <DownloadIcon className="h-4 w-4" />
                  <span className="text-sm">Buy</span>
                </a> */}
                  </div>
                </div>
              ))
            )}
            <div className="snap-center shrink-0">
              <div className="shrink-0 w-4 sm:w-48"></div>
            </div>
          </div>
        </div>
        <footer className="text-xs w-full bg-slate-900 text-white flex justify-center items-center h-24">
          <span>
            Copyright &copy; 2021 Levi Barker All rights reserved.
          </span>
        </footer>
      </section>
    </div>
  );
}

export default Home;
