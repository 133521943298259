import * as React from "react"

const HashnodeLogoComponent = (props: any) => (
    <svg width="337" height="337" fill="none" viewBox="0 0 337 337" {...props}>
        <rect width="111" height="111" x="113" y="113" fill="#fff" rx="55.5"></rect>
        <path
            fill="#2962FF"
            fillRule="evenodd"
            d="M23.155 112.598c-30.873 30.874-30.873 80.93 0 111.804l89.443 89.443c30.874 30.873 80.93 30.873 111.804 0l89.443-89.443c30.873-30.874 30.873-80.93 0-111.804l-89.443-89.443c-30.874-30.873-80.93-30.873-111.804 0zm184.476 95.033c21.612-21.611 21.612-56.651 0-78.262-21.611-21.612-56.651-21.612-78.262 0-21.612 21.611-21.612 56.651 0 78.262 21.611 21.612 56.651 21.612 78.262 0"
            clipRule="evenodd"
        ></path>
    </svg>
)

export default HashnodeLogoComponent