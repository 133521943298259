import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./views/Home";
import { CheckoutConfirmation } from "./views/CheckoutConfirmation";

function App() {
  return (
    <Router>
      <Routes>
        <Route
          path="/checkout-confirmation"
          element={<CheckoutConfirmation />}
        ></Route>
        <Route path="/" element={<Home />}></Route>
      </Routes>
    </Router>
  );
}

export default App;
