import React from "react";
import { useSearchParams } from "react-router-dom";
import { BadgeCheckIcon } from "@heroicons/react/outline";

export const CheckoutConfirmation = () => {

    let [searchParams] = useSearchParams();

    return (
        <div className="h-screen flex items-center justify-center">
            <div className="w-96 flex flex-col justify-center items-center">
                <BadgeCheckIcon className="fade-in-1 h-32 text-green-600 pb-4" />
                <span className="fade-in-2 text-3xl font-semibold pb-4">Payment Successful</span>
                <span className="fade-in-3 center">Thank you for your purchase. An email has been sent to your inbox with a download link.  If you have any questions, please email <a className="text-blue-500 hover:underline" href="mailto:levi@levibarker.com">levi@levibarker.com</a>.</span>
                {/* <span>Checkout session ID: {searchParams.get('checkoutSessionId')}</span> */}
            </div>

        </div>
    );
}